import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Subject } from 'rxjs';
import { IRequestCreateHistory } from '../../features/fixed-term-deposit/interfaces';
import { LocalStorageKeyType } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class FixedTermDepositHistoryStorageService {
  localStorageService: LocalStorageService = inject(LocalStorageService);
  private fixedTermDepositHistoryDataSubject: Subject<
    Array<IRequestCreateHistory>
  > = new Subject<Array<IRequestCreateHistory>>();

  constructor() {
    const storedData = this.localStorageService.getItem(
      LocalStorageKeyType.FIXED_TERM_DEPOSIT_HISTORY
    );
    const fixedTermDepositHistoryData: Array<IRequestCreateHistory> = storedData
      ? storedData
      : [];
    this.fixedTermDepositHistoryDataSubject.next(fixedTermDepositHistoryData);
  }

  setFixedTermDepositHistoryData(data: IRequestCreateHistory) {
    const storedData = this.localStorageService.getItem(
      LocalStorageKeyType.FIXED_TERM_DEPOSIT_HISTORY
    );
    const listData = storedData ? storedData : [];
    listData.push(data);
    this.localStorageService.setItem(
      LocalStorageKeyType.FIXED_TERM_DEPOSIT_HISTORY,
      listData
    );
    this.fixedTermDepositHistoryDataSubject.next(listData);
  }

  clearFixedTermDepositHistoryData() {
    this.localStorageService.removeItem(
      LocalStorageKeyType.FIXED_TERM_DEPOSIT_HISTORY
    );
    this.fixedTermDepositHistoryDataSubject.next([]);
  }

  getFixedTermDepositHistoryData(): Array<IRequestCreateHistory> | null {
    return this.localStorageService.getItem(
      LocalStorageKeyType.FIXED_TERM_DEPOSIT_HISTORY
    );
  }
}
